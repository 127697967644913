<template>
	<v-row>
		<v-col cols="7">
			<v-card>
				<v-card-title primary-title>
					Post Test
				</v-card-title>
				<v-card-text>
					<h2 style="text-transform: capitalize;">{{ pelatihan.nama }}</h2>
					<h3>Detail Stakeholder Pelatihan:</h3>
					<div v-html="pelatihan.detail_stakeholder"></div>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-card>
				<v-card-text>
					<h3>Daftar Pertanyaan Post Test</h3>
					<v-btn v-if="tableHasil.items.length == 0" x-small color="primary" @click="add()">Tambah</v-btn>
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th>No</th>
									<th>Pertanyaan/ Jawaban</th>
									<th>Aksi</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, key) in table.items" :key="item.id">
									<td>{{ key + 1 }}</td>
									<td>{{ item.pertanyaan }}
										<br />
										<ol type="A">
											<li>{{ item.jawaban1 }}</li>
											<li>{{ item.jawaban2 }}</li>
											<li>{{ item.jawaban3 }}</li>
											<li>{{ item.jawaban4 }}</li>
											<li>{{ item.jawaban5 }}</li>
										</ol>
										<b>Kunci:</b> {{ item.kunci }}
									</td>
									<td>
										<div v-if="tableHasil.items.length == 0">
											<v-icon small color="primary" @click="edit(item)">
												{{ icons.mdiPencil }}
											</v-icon>
											<v-icon small color="error" @click="deletePertanyaanSaved(item)">
												{{ icons.mdiTrashCan }}
											</v-icon>
										</div>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>

					<!-- dialog pertanyaan -->
					<v-layout row justify-center>
						<v-dialog v-model="form.dialog" max-width="800">
							<v-card>
								<v-card-title primary-title>
									Tambah Pertanyaan
								</v-card-title>
								<v-card-text>
									<template>
										<v-form lazy-validation>
											<v-textarea label="Isi Pertanyaan :" v-model="form.edit.pertanyaan" dense
												outlined></v-textarea>
											<v-text-field label="Jawaban A :" v-model="form.edit.jawaban1" dense
												outlined required></v-text-field>
											<v-text-field label="Jawaban B :" v-model="form.edit.jawaban2" dense
												outlined required></v-text-field>
											<v-text-field label="Jawaban C :" v-model="form.edit.jawaban3" dense
												outlined required></v-text-field>
											<v-text-field label="Jawaban D :" v-model="form.edit.jawaban4" dense
												outlined required></v-text-field>
											<v-text-field label="Jawaban E :" v-model="form.edit.jawaban5" dense
												outlined required></v-text-field>
											<v-radio-group v-model="form.edit.kunci" row>
												<v-radio label="A" value="jawaban1"></v-radio>
												<v-radio label="B" value="jawaban2"></v-radio>
												<v-radio label="C" value="jawaban3"></v-radio>
												<v-radio label="D" value="jawaban4"></v-radio>
												<v-radio label="E" value="jawaban5"></v-radio>
											</v-radio-group>
											<v-btn color="primary" @click="savePertanyaan()">
												Simpan
											</v-btn>
											<v-btn class="ml-2" color="warning" @click="reset()">Reset</v-btn>
										</v-form>
									</template>
								</v-card-text>
							</v-card>
						</v-dialog>
					</v-layout>
				</v-card-text>
			</v-card>

			<v-card class="mt-4">
				<v-card-text>
					<h3>Hasil Post Test</h3>
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th>No</th>
									<th>Nama</th>
									<th>Nilai</th>
									<th>Hasil</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, key) in tableHasil.items" :key="item.id">
									<td>{{ key + 1 }}</td>
									<td>{{ item.nama }}</td>
									<td>{{ item.nilai_akhir }}</td>
									<td>
										<v-chip v-if="item.nilai_akhir >= 80" color="success" small>Lulus</v-chip>
										<v-chip v-else color="error" small>Tidak Lulus</v-chip>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<style scoped>
table tr td {
	vertical-align: top;
}

.pdf {
	width: 100%;
	aspect-ratio: 4 / 3;
}

.pdf,
html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
}
</style>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiPencil, mdiMagnify, mdiPlus, mdiTrashCan, mdiLock, mdiClose } from '@mdi/js'

export default {
	components: {
	},
	data() {
		return {
			icons: {
				mdiEye,
				mdiPencil,
				mdiMagnify, mdiPlus, mdiTrashCan, mdiLock, mdiClose
			},
			id_pelatihan: this.$route.params.id_pelatihan,
			pelatihan: {},
			materi: {
				tanggal: '',
				nama: '',
				deskripsi: '',
				jenis_tugas: '',
				nama_user: '',
				file_url: '',
			},
			form: {
				editedIndex: -1,
				dialog: false,
				edit: {
					pertanyaan: '',
					jawaban1: '',
					jawaban2: '',
					jawaban3: '',
					jawaban4: '',
					jawaban5: '',
					kunci: '',
				},
				default: {
					pertanyaan: '',
					jawaban1: '',
					jawaban2: '',
					jawaban3: '',
					jawaban4: '',
					jawaban5: '',
					kunci: '',
				}
			},
			table: {
				items: []
			},
			tableHasil: {
				items: [],
				jumlahResponden: 0,
			},
		}
	},
	watch: {
	},
	computed: {
	},
	created() {
		this.getPelatihan()
		this.getPertanyaans()
		this.getHasilTest()
	},
	methods: {
		getPelatihan() {
			const fmData = new FormData()
			fmData.append('id_pelatihan', this.id_pelatihan)
			axiosPostAuth('api/Pelatihan/getPelatihan', fmData)
				.then(response => {
					this.pelatihan = Object.assign({}, response.data)
				})
				.catch(error => {
					alert(error)
				})
		},
		savePertanyaan() {
			if (this.form.editedIndex > -1) {
				const fmData = new FormData()
				fmData.append('aksi', 'update')
				fmData.append('jenis', 'POST TEST')
				fmData.append('id_pelatihan', this.id_pelatihan)
				fmData.append('id', this.form.edit.id)
				fmData.append('pertanyaan', this.form.edit.pertanyaan)
				fmData.append('jawaban1', this.form.edit.jawaban1)
				fmData.append('jawaban2', this.form.edit.jawaban2)
				fmData.append('jawaban3', this.form.edit.jawaban3)
				fmData.append('jawaban4', this.form.edit.jawaban4)
				fmData.append('jawaban5', this.form.edit.jawaban5)
				fmData.append('kunci', this.form.edit.kunci)
				axiosPostAuth('api/CBT/savePertanyaan', fmData)
					.then(response => {
						if (response.code === 200) {
							this.$notify({
								type: 'success',
								title: 'Berhasil!',
								text: response.message,
							});
							this.getPertanyaans()
							this.form.dialog = false
						}
						else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			} else {
				const fmData = new FormData()
				fmData.append('aksi', 'add')
				fmData.append('jenis', 'POST TEST')
				fmData.append('id_pelatihan', this.id_pelatihan)
				fmData.append('pertanyaan', this.form.edit.pertanyaan)
				fmData.append('jawaban1', this.form.edit.jawaban1)
				fmData.append('jawaban2', this.form.edit.jawaban2)
				fmData.append('jawaban3', this.form.edit.jawaban3)
				fmData.append('jawaban4', this.form.edit.jawaban4)
				fmData.append('jawaban5', this.form.edit.jawaban5)
				fmData.append('kunci', this.form.edit.kunci)
				axiosPostAuth('api/CBT/savePertanyaan', fmData)
					.then(response => {
						if (response.code === 200) {
							this.$notify({
								type: 'success',
								title: 'Berhasil!',
								text: response.message,
							});
							this.getPertanyaans()
						}
						else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})

			}
		},
		getPertanyaans() {
			const fmData = new FormData()
			fmData.append('id_pelatihan', this.id_pelatihan)
			fmData.append('jenis', 'POST TEST')
			axiosPostAuth('api/CBT/getMasterPertanyaan', fmData)
				.then(response => {
					this.table.items = response.data
				})
				.catch(error => {
					alert(error)
				})
		},
		deletePertanyaanSaved(item) {
			if (confirm("Apakah yakin akan dihapus?")) {
				const fmData = new FormData()
				fmData.append('id_pertanyaan', item.id)
				fmData.append('id_pelatihan', this.id_pelatihan)
				axiosPostAuth('api/CBT/deleteEvaluasiPenyelenggaraPertanyaan', fmData)
					.then(response => {
						if (response.code == 200) {
							this.$notify({
								type: 'success',
								title: 'Berhasil!',
								text: response.message,
							});
							this.getPertanyaans()
						} else {
							this.$notify({
								type: 'error',
								title: 'Gagal!',
								text: response.message,
							});
						}
					})
					.catch(error => {
						alert(error)
					})
			}
		},
		add() {
			this.form.editedIndex = -1
			this.form.edit = Object.assign({}, this.form.default)
			this.form.dialog = true
		},
		edit(item) {
			this.form.editedIndex = this.table.items.indexOf(item)
			this.form.edit = Object.assign({}, item)
			if (this.form.edit.kunci == this.form.edit.jawaban1) {
				this.form.edit.kunci = 'jawaban1'
			} else if (this.form.edit.kunci == this.form.edit.jawaban2) {
				this.form.edit.kunci = 'jawaban2'
			} else if (this.form.edit.kunci == this.form.edit.jawaban3) {
				this.form.edit.kunci = 'jawaban3'
			} else if (this.form.edit.kunci == this.form.edit.jawaban4) {
				this.form.edit.kunci = 'jawaban4'
			} else if (this.form.edit.kunci == this.form.edit.jawaban5) {
				this.form.edit.kunci = 'jawaban5'
			}
			this.form.dialog = true
		},
		getHasilTest() {
			const fmData = new FormData()
			fmData.append('id_pelatihan', this.id_pelatihan)
			fmData.append('jenis', 'POST TEST')
			axiosPostAuth('api/CBT/getHasilTest', fmData)
				.then(response => {
					this.tableHasil.items = response.data
				})
				.catch(error => {
					alert(error)
				})
		},
		reset(){
			this.form.edit = this.form.default
		}
	},
}
</script>